import React from 'react';
import { useState, useEffect, useRef } from 'react';
import useLiveStats from './useLiveStats';
import CountUp from 'react-countup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const DonationStats = () => {
  const [showFeeInfo, setShowFeeInfo] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const hasAnimated = useRef(false);

  const liveStats = useLiveStats();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated.current) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );
  
    const currentElement = document.querySelector('#impact');
    if (currentElement) {
      observer.observe(currentElement);
    }
  
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  const formatNumber = (num) => num.toLocaleString('en-US');

  const formatCurrency = (num) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);

  const formatPercentage = (num) =>
    new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num / 100);

  const stats = [
    { label: 'Total Raised', value: liveStats?.totalAmount || 0, format: formatCurrency },
    { label: 'Donations Processed', value: liveStats?.donationCount || 0, format: formatNumber },
    { label: 'Average Donation Size', value: liveStats?.avgAmount || 0, format: formatCurrency },
    { label: 'Total Donors', value: liveStats?.donorCount || 0, format: formatNumber },
  ];

  const fees =
    { label: 'Average Fee Percentage Across GiveMasjid Masjids', value: liveStats?.averageFeePercentage || "", format: formatPercentage };

  return (
    <div className="bg-gray-900 py-16 sm:py-24" id="impact">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl mb-2">
            Trusted by <span className="text-emerald-500">Masjids</span> and{' '}
            <span className="text-emerald-500">Muslims</span> Everywhere
          </h2>
          <p className="text-xl leading-8 text-gray-300 mb-12">
            Since we first launched GiveMasjid in early 2023, we've tried sincerely to help our masjid partners grow.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-12">
          {stats.map((item, index) => (
            <div key={index} className="bg-white/5 rounded-lg p-6 text-center">
              <dt className="text-sm font-semibold text-gray-300 mb-2">{item.label}</dt>
              <dd className={`text-4xl font-bold ${index === 0 ? 'text-emerald-400' : 'text-white'}`}>
              {isVisible && !hasAnimated.current ? (
                <CountUp 
                    key={`stat-${index}`}
                    end={item.value} 
                    duration={2.5} 
                    formattingFn={item.format}
                    onEnd={() => {
                      if (index === stats.length - 1) {
                        hasAnimated.current = true;
                      }
                    }}
                  />
                ) : (
                  item.format(item.value)
                )}
              </dd>
            </div>
          ))}
        </div>

        <div className="text-center pt-5 sm:pt-14">
          <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl mb-2">
            <span className="text-white-500">Low Transaction Fees</span> and{' '}
            <span className="text-white-500">No Fixed Costs</span>
          </h2>

          <p className="leading-7 text-center text-lg lg:text-xl text-gray-300">
            We do everything to ensure our partner masjids keep as much of their funds possible — by eliminating fixed monthly fees, offering the lowest transaction fees of any fundraising platform, nudging donors to chip in, and helping large donors to use low cost bank transfers.
          </p>
        </div>

        <div className="flex flex-col items-center mt-11 relative">
          <div className="bg-white/10 rounded-lg p-6 text-center w-full max-w-lg relative z-10">
            <p className="text-4xl font-bold text-emerald-400 mb-2">
              {fees.format(fees.value)}
            </p>
            <p className="text-base text-gray-300">{fees.label}</p>
          </div>

          {/* Mobile version */}
          <div className="mt-4 flex flex-col items-center w-full max-w-lg relative z-10 md:hidden">
            <div className="w-full border border-gray-600 rounded-lg overflow-hidden transition-all duration-300 ease-in-out">
              <button
                className={`w-full bg-white/5 py-2 px-4 text-sm flex items-center justify-between hover:bg-white/10 transition-colors duration-300 ${showFeeInfo ? 'text-gray-300' : 'text-gray-500'
                  }`}
                onClick={() => setShowFeeInfo(!showFeeInfo)}
              >
                <div className="flex items-center group">
                  <div className="bg-white/10 p-2 rounded-md mr-3">
                    <FontAwesomeIcon icon={faCircleQuestion} className="text-lg" />
                  </div>
                  <span className="italic font-medium group-hover:text-gray-300 transition-colors duration-300">Looking to Learn More About Our Fees?</span>
                </div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`transform transition-transform duration-300 ${showFeeInfo ? 'rotate-180' : ''}`}
                />
              </button>
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${showFeeInfo ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
                  }`}
              >
                <div className="p-4 text-sm text-gray-400">
                  <div className="flex">
                    <div className="w-9 flex-shrink-0"></div>
                    <div className="space-y-3 flex-grow">
                      <p>
                        We <span className="font-semibold">know</span> fees can feel tricky.
                      </p>
                      <p>
                      But we're confident in saying that we offer one of the lowest (and fairest) fee structures of any donation platform out there. As an organization focused on helping the Muslim community, we at GiveMasjid charge only a 0.8% platform fee for every donation.
                      </p>
                      <p>
                        The number you see here shows what our average masjid pays per donation, inclusive of our platform fee, Stripe's credit card processing fees, and donors' typical coverage. This means that for every $1,000 your masjid raises, you can expect to pay just  <span className="text-gray-400 font-semibold">{formatCurrency(1000 * (fees.value / 100))}</span> in fees if you use GiveMasjid. This is far lower than what most masjids pay when they use other popular platforms like Paypal, Mohid, Venmo, or DonorBox, or Square.
                      </p>
                      <p>
                        You can learn more about how our fees work by{' '}
                        <a href="#contact" className="text-gray-400 underline hover:text-gray-100 transition-colors duration-300">
                          reaching out to a member of our team
                        </a>.
                      </p>                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

{/* Desktop version */}
<div className="mt-10 hidden md:flex flex-col items-center w-full max-w-2xl sm:max-w-6xl relative z-10">
  <div className="w-full">
    <button
      onClick={() => setShowFeeInfo(!showFeeInfo)}
      className={`w-full flex items-center justify-between p-4 rounded-lg border border-gray-800 ${
        showFeeInfo ? 'bg-white/5' : 'bg-transparent'
      } transition-colors duration-300`}
    >
      <div className="w-8">
        {/* This empty div balances the layout */}
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faCircleQuestion} className="text-gray-500 mr-3 text-xl" />
        <h3 className={`text-xl font-semibold ${
          showFeeInfo ? 'text-gray-300' : 'text-gray-400'
        } hover:text-gray-300 transition-colors duration-300`}>
          Looking to Learn More About Our Fees?
        </h3>
      </div>
      <FontAwesomeIcon
        icon={faChevronDown}
        className={`text-gray-400 w-8 transform transition-transform duration-300 ${
          showFeeInfo ? 'rotate-180' : ''
        }`}
      />
    </button>
    
    <div className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${
      showFeeInfo ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
    }`}>
      <div className="p-6 rounded-lg border border-gray-800">
      <div className="text-sm sm:text-lg text-center text-gray-400 sm:text-gray-500 space-y-4">
          <p>
            <span className="text-gray-400 font-semibold">We know fees can feel tricky</span>.
          </p>
          <p>
          But we're confident in saying that we offer one of the lowest (and fairest) fee structures of any donation platform out there. As an organization focused on helping the Muslim community, we at GiveMasjid charge only a 0.8% platform fee on every donation to cover our costs. The number you see above shows what our average masjid pays per donation, inclusive of our platform fee, Stripe's credit card processing fees, and donors' typical coverage patterns. This means that for every $1,000 your masjid raises, you can expect to pay just  <span className="text-gray-400 font-semibold">{formatCurrency(1000 * (fees.value / 100))}</span> in fees if you use GiveMasjid. This is lower than what most masjids pay when they use other popular platforms like Paypal, Mohid, Venmo, or DonorBox, or Square.
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
          <p className="italic">
            You can learn more about how our pricing system by{' '}
            <a href="#contact" className="text-gray-500 underline hover:text-gray-300 transition-colors duration-300">
              reaching out to a member of our team
            </a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
    </div>
  );

};

export default DonationStats;
