import { useState, useEffect, useRef } from 'react';
import aamir from '../assets/aamir.jpg'; 
import bilal from '../assets/bilal.jpg'; 
import omar from '../assets/omar.jpg'; 
import logo512 from '../assets/logo512.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const testimonials = [

  {
    name: "Bilal S.",
    role: "Islamic Society of New Tampa",
    organization: "Islamic Society of New Tampa",
    location: "Tampa, Florida",
    image: bilal,
    quote: "Working with GiveMasjid has been fantastic. Their donation platform works great and our donors find it easy to use. We've seen a significant increase in the number of subscription donations since we started using the platform. More importantly, the team is dedicated and responsive. They even created features specifically for our masjid to help us more easily handle time-consuming tasks related to school payments and reporting. It's  clear the GiveMasjid team cares deeply about the success of our masjid.", 
    tagline: "Since starting to use the GiveMasjid platform, we've seen a 50% increase in monthly donations and double the number of donors."
  },
  {
    name: "Aamir N.",
    role: "Faith Unity Islamic Center",
    organization: "Faith Unity Islamic Center",
    location: "Bensalem, Pennsylvania",
    image: aamir,
    quote: "GiveMasjid was a huge help in allowing us to reach would-be donors who wanted to support our masjid but didn't regularly carry cash. The clean interface made it easy for us to set and track weekly goals, for people to enroll themselves as recurring donors especially on Fridays and during Ramadan, and eliminated some of the friction we had with other online fundraising platforms in the past.", 
    tagline: "In our first year of using GiveMasjid , we collected over $85k in donations."
  },
  {
    name: "Omar E.",
    role: "Co-Chair Grassroots Islam",
    organization: "Grasroots Islam Community",
    location: "Austin, Texas",
    image: omar,
    quote: "We've been using GiveMasjid for the last two years to collect donations for our distributed Islamic community. Each time our members have made a donation they've complimented us on how simple and easy the experience has been. GiveMasjid is just far more polished than anything they're used to seeing at their local masjids.",
    tagline: "Thanks to GiveMasjid, we were able to very quickly raise funds to cover our most recent annual retreat."
  },
  {
    name: "Mohammed",
    role: "Board Member, NYMA",
    organization: "North York Muslim Association",
    location: "Toronto, Ontario (CA)",
    image: logo512,
    quote: "GiveMasjid was the perfect fit for North York Muslim Association's needs. Their payment platform solution combined with personalized service made the implementation seamless. Ahmed (CEO) went above and beyond to customize features for our specific requirements.", 
    tagline: "We're grateful for their partnership and the difference they've made for our community."
  }
];



export default function MasjidTestimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const testimonialsPerPage = {
    mobile: 1,
    desktop: 2
  };
  
  const totalPages = Math.ceil(testimonials.length / (window.innerWidth < 1024 ? testimonialsPerPage.mobile : testimonialsPerPage.desktop));
  
  // Reference to the testimonial section
  const testimonialRef = useRef(null);

  const scrollToTop = () => {
    if (testimonialRef.current) {
      testimonialRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handlePrevious = () => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        // For mobile: move one at a time
        if (window.innerWidth < 1024) {
          return prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1;
        }
        // For desktop: move two at a time
        return prevIndex === 0 ? testimonials.length - testimonialsPerPage.desktop : prevIndex - testimonialsPerPage.desktop;
      });
      setIsTransitioning(false);
      scrollToTop();
    }, 300);
  };
  
  const handleNext = () => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        // For mobile: move one at a time
        if (window.innerWidth < 1024) {
          return (prevIndex + 1) % testimonials.length;
        }
        // For desktop: move two at a time
        return (prevIndex + testimonialsPerPage.desktop) % testimonials.length;
      });
      setIsTransitioning(false);
      scrollToTop();
    }, 300);
  };
  
  // Get current testimonials based on screen size
  const getCurrentTestimonials = () => {
    // For mobile (1 testimonial)
    if (window.innerWidth < 1024) {
      // Make sure we're using the correct index for mobile
      const mobileIndex = currentIndex % testimonials.length;
      return [testimonials[mobileIndex]];
    }
    
    // For desktop (2 testimonials)
    const firstIndex = currentIndex % testimonials.length;
    const secondIndex = (currentIndex + 1) % testimonials.length;
    return [
      testimonials[firstIndex],
      testimonials[secondIndex]
    ];
  };
  
  // Use window resize event to update the view
  useEffect(() => {
    const handleResize = () => {
      // Force a re-render when window size changes
      setCurrentIndex(prev => prev);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <section ref={testimonialRef} className="bg-gray-900 pt-10 sm:pt-32 sm:pb-20 pb-20" id="testimonials">
      <div className="bg-gray-900 px-6 lg:px-8 pb-4">
        <div className="mx-auto max-w-4xl sm:text-center px-6">
          <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">Loved by <span className="text-emerald-500">Masjids Like Yours</span></h2>
          <p className="mt-3 text-lg leading-8 text-gray-300">
            Hear what others have to say. With GiveMasjid collecting donations doesn't have to be a pain.
          </p>
        </div>
      </div>
      
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-10 relative">
        {/* Navigation Arrows */}
        <div className="absolute top-1/2 left-2 md:left-0 transform -translate-y-1/2 z-10">
          <button 
            onClick={handlePrevious}
            className="bg-gray-800 hover:bg-gray-700 text-white rounded-full p-3 focus:outline-none shadow-lg transition-all duration-200 hover:scale-110"
            aria-label="Previous testimonials"
            disabled={isTransitioning}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        
        <div className="absolute top-1/2 right-2 md:right-0 transform -translate-y-1/2 z-10">
          <button 
            onClick={handleNext}
            className="bg-gray-800 hover:bg-gray-700 text-white rounded-full p-3 focus:outline-none shadow-lg transition-all duration-200 hover:scale-110"
            aria-label="Next testimonials"
            disabled={isTransitioning}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        
        {/* Testimonials */}
        <div className={`mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2 transition-opacity duration-300 ${isTransitioning ? 'opacity-0' : 'opacity-100'} px-8 md:px-12`}>
          {getCurrentTestimonials().map((testimonial, index) => (
            <div key={index} className={`flex flex-col ${index !== 0 ? 'border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20' : 'pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20'}`}>
              <h3 className="text-xl font-bold tracking-tight text-indigo-400 sm:text-xl ">{testimonial.organization}</h3>
              <p className="text-lg tracking-tight text-white sm:text-lg">{testimonial.location}</p>
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-white">
                  <svg className="w-8 h-8 text-gray-400 dark:text-gray-600 mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
                  </svg>
                  <p className="font-extralight">{testimonial.quote}</p>
                  <br></br>
                  <p className="font-light italic">{testimonial.tagline}</p>

                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <img
                    className="h-14 w-14 rounded-full bg-gray-800"
                    src={testimonial.image}
                    alt=""
                  />
                  <div className="text-base">
                    <div className="font-semibold text-white">{testimonial.name}</div>
                    <div className="mt-1 text-gray-400">{testimonial.role}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </div>
        
        {/* Pagination Indicators */}
        <div className="flex justify-center mt-8 space-x-2">
          {Array.from({ length: testimonials.length }).map((_, index) => (
            <button
              key={index}
              onClick={() => {
                setIsTransitioning(true);
                setTimeout(() => {
                  setCurrentIndex(index);
                  setIsTransitioning(false);
                  scrollToTop();
                }, 300);
              }}
              className={`h-2 w-2 rounded-full ${
                currentIndex % testimonials.length === index 
                  ? 'bg-emerald-500' 
                  : 'bg-gray-600'
              }`}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
