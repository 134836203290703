// src/components/TrialButton.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { GiftIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faMosque } from '@fortawesome/free-solid-svg-icons';

const TrialButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  // Original link was:
  // <Link to="/request-trial" className="...">
  //   <FontAwesomeIcon icon={faMosque} className="text-lg mr-2" />
  //   <span>Try GiveMasjid <span className="text-sm italic text-gray-300">for free</span></span>
  // </Link>
  
  return (
    <>
    <Link
      to="/request-trial"
      className={`text-xs lg:text-sm fixed bottom-4 left-1/2 -translate-x-1/2 md:left-auto md:right-4 md:-translate-x-0 bg-gradient-to-r from-emerald-600 to-emerald-700 hover:from-emerald-600 hover:to-emerald-800 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-100 ease-in-out transform hover:scale-105 z-50 flex items-center whitespace-nowrap transition-opacity ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } duration-[800ms] ease-in-out`}
    >
      <FontAwesomeIcon icon={faMosque} className="text-sm lg:text-base mr-2" />
      <span className="lg:text-base">Sign Up for a Trial</span>
    </Link>
    <a
      href="sms:4159094596"
      className={`text-xs lg:text-sm fixed bottom-14 lg:bottom-16 left-1/2 -translate-x-1/2 md:left-auto md:right-4 md:-translate-x-0 bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-100 ease-in-out transform hover:scale-105 z-50 flex items-center whitespace-nowrap transition-opacity ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } duration-[800ms] ease-in-out`}
    >
      <FontAwesomeIcon icon={faComments} className="text-sm md:text-base mr-2" />
      <span className="text-xs lg:text-base">Questions? Text/Call: <span className="text-xs lg:text-base font-medium">(415) 909-4596</span></span>
    </a>
    </>
  );
};

export default TrialButton;
