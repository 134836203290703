import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FomoNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const apiUrl = 'https://donate.givemasjid.com/stats/staged-fomo';

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(apiUrl);
        const filteredNotifications = response.data.filter(notification => 
          notification.donation !== null && 
          notification.daysAgo !== null
        );
        setNotifications(filteredNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      let index = 0;
      
      const showNextNotification = () => {
        if (index < notifications.length) {
          setCurrentNotification(notifications[index]);
          setIsMounted(true);
          setTimeout(() => setIsVisible(true), 50); // Short delay to trigger fade-in
          
          setTimeout(() => {
            setIsVisible(false);
            setTimeout(() => {
              setIsMounted(false);
              index = (index + 1) % notifications.length;
              setTimeout(showNextNotification, 5000); // Wait 5 seconds before showing the next notification
            }, 700); // Wait for slide-out to complete
          }, 10000); // Keep the notification visible for 10 seconds
        }
      };
      
      // Initial delay before starting the notifications
      setTimeout(showNextNotification, 10000);
    }
  }, [notifications]);

  if (!currentNotification || !isMounted) return null;

  const formattedDonation = currentNotification.donation.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div 
      className={`fixed bottom-28 hidden md:block md:left-auto md:right-4 md:-translate-x-0 bg-white shadow-lg rounded-lg p-4 w-[90vw] max-w-md transition-all duration-700 ${
        isVisible 
          ? 'opacity-100 left-1/2 -translate-x-1/2' 
          : 'opacity-0 translate-x-full'
      } z-[60]`}
    >
      <p className="text-xs md:text-base text-center">
        ⭐ A GiveMasjid masjid just received <span className="font-semibold">${formattedDonation}.</span> <span className="text-xs md:text-xs text:gray-300"> {currentNotification.daysAgo} days ago</span>
      </p>
    </div>
  );
};

export default FomoNotification;
